import { cx } from "@emotion/css";
import React, { ReactNode } from "react";
import { Link, LinkProps } from "react-router-dom";

interface ButtonLinkProps extends LinkProps {
  className?: string;
  children: ReactNode;
  variant?: string;
  size?: string;
}

const ButtonLink = ({
  children,
  className,
  variant = "primary",
  size = "md",
  ...props
}: ButtonLinkProps) => {
  const finalClassName = cx(className, "btn", `btn-${variant}`, `btn-${size}`);

  return (
    <Link {...props} className={finalClassName}>
      <span>{children}</span>
    </Link>
  );
};

export default ButtonLink;
