import { useEffect, useState } from "react";
import { Card, Col, ListGroup, Ratio, Row } from "react-bootstrap";
import { GetHubs } from "../services/hub.service";
import { HubResource } from "../models/hub";
import ButtonLink from "./ButtonLink";

const HubList = () => {
  const [hubs, setHubs] = useState<HubResource[] | undefined>();

  const fetchData = async () => {
    await GetHubs()
      .then((hubs) => {
        setHubs(hubs);
      })
      .catch();
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <h2>Hub List</h2>
      <p>Select a hub to view its booking information</p>

      <Row>
        {hubs?.map((hub) => (
          <Col
            key={hub.id}
            xs={12}
            sm={6}
            md={4}
            style={{ marginBottom: "1rem" }}
          >
            <Card style={{ height: "100%" }}>
              <Ratio aspectRatio="16x9">
                <Card.Img variant="top" src={hub.featuredMedia?.url} />
              </Ratio>
              <Card.Body>
                <Card.Title>{hub.name}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  {hub.site?.name}
                </Card.Subtitle>
                <Card.Text>{hub.overview}</Card.Text>
              </Card.Body>
              <ListGroup className="list-group-flush">
                <ListGroup.Item>{hub.site?.addressLine0}</ListGroup.Item>
                <ListGroup.Item>{hub.site?.addressLine1}</ListGroup.Item>
              </ListGroup>

              <Card.Body>
                <div className="d-grid gap-2">
                  <ButtonLink to={`/hub/${hub.id}`} variant="primary">
                    View Bookings
                  </ButtonLink>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default HubList;
