import { ReactNode } from "react";
import { Container } from "react-bootstrap";

const Page = ({
  children,
  title = "",
}: {
  children: ReactNode | ReactNode[];
  title?: string | ReactNode;
}) => {
  return (
    <>
      <Container className="p-5 mb-4 bg-light" fluid>
        <Container>
          <h1 className="header">{title}</h1>
        </Container>
      </Container>

      <Container className="pt-3">{children}</Container>
    </>
  );
};

export default Page;
