import React, { ReactNode } from "react";

export const DateFormats = {
  date: new Intl.DateTimeFormat("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    timeZone: "UTC",
  }),
  dateTime: new Intl.DateTimeFormat("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    hourCycle: "h23",
    minute: "numeric",
    timeZone: "UTC",
  }),
  dateTimeSimple: new Intl.DateTimeFormat("en-GB", {
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
  }),
  time: new Intl.DateTimeFormat("en-GB", {
    hour: "2-digit",
    hourCycle: "h23",
    minute: "numeric",
    timeZone: "UTC",
  }),
  day: new Intl.DateTimeFormat("en-GB", {
    day: "numeric",
    timeZone: "UTC",
  }),
  dayOfWeekString: new Intl.DateTimeFormat("en-GB", {
    weekday: "long",
    timeZone: "UTC",
  }),
  monthText: new Intl.DateTimeFormat("en-GB", {
    month: "long",
    timeZone: "UTC",
  }),
  yearFull: new Intl.DateTimeFormat("en-GB", {
    year: "numeric",
    timeZone: "UTC",
  }),
};

const DateFormat = ({
  date,
  format,
}: {
  date: Date | undefined;
  format: Intl.DateTimeFormat;
}) => {
  return <>{date && format.format(date)}</>;
};

export const UTC = ({ children }: { children: ReactNode }) => (
  <span title="This is the internal server time and may differ from the local time">
    {children}
  </span>
);

export const DateUTCInfo = () => (
  <UTC>
    {/* <MdOutlineInfo /> */}
  </UTC>
);

export default DateFormat;
