import { Container } from "react-bootstrap";

function Footer() {
  return (
    <Container className="p-5 mt-5 bg-light" fluid>
      <Container>
        <p>WorkfromHub © 2022</p>
      </Container>
    </Container>
  );
}

export default Footer;
