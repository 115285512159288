import { useEffect, useState } from "react";
import { Card, ListGroup } from "react-bootstrap";
import { GetHubTimes } from "../services/hub.service";
import { HubTimes } from "../models/hubDates";
import DateFormat, { DateFormats } from "./DateFormat";

const BookingList = ({ id }: { id?: number }) => {
  const [times, setTimes] = useState<HubTimes | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const date = new Date();

  useEffect(() => {
    const date = new Date();

    const fetchTimes = async () => {
      setIsLoading(true);
      if (id) {
        await GetHubTimes(id, date)
          .then((x) => {
            setTimes(x);
            setIsLoading(false);
            return;
          })
          .catch((err) => {
            // TODO: 404 display
            console.log(err);
            return;
          });
      }
    };

    fetchTimes();
  }, [id]);

  return (
    <>
      {!isLoading && times?.allocations.length === 0 ? (
        <Card bg={"danger"} text={"light"}>
          <Card.Body>
            <Card.Title>Todays Bookings</Card.Title>
            <p>There are no bookings today</p>
          </Card.Body>
        </Card>
      ) : (
        <Card>
          <Card.Body>
            <Card.Title>Todays Bookings</Card.Title>
          </Card.Body>
          <ListGroup variant="flush">
            {times?.allocations
              .sort(
                (objA, objB) =>
                  objA.startDate.getTime() - objB.startDate.getTime()
              )
              .map((time) => (
                <ListGroup.Item
                  key={time.endDate.toISOString()}
                  as="li"
                  active={
                    time.startDate < date && time.endDate > date ? true : false
                  }
                  disabled={date > time.endDate ? true : false}
                >
                  <>
                    <DateFormat
                      date={time.startDate}
                      format={DateFormats.time}
                    />
                    -
                    <DateFormat date={time.endDate} format={DateFormats.time} />
                  </>
                </ListGroup.Item>
              ))}
          </ListGroup>
        </Card>
      )}
    </>
  );
};

export default BookingList;
