import Footer from "./components/footer";
import NavBar from "./components/navbar";
import AppRoutes from "./pages/app.routes";

function App() {
  return (
    <>
      <NavBar />
      <AppRoutes />
      <Footer /> 
    </>
  );
}

export default App;
