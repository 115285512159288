import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import HomePage from "./home/home.page";
import HubPage from "./hub/hub.page";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/hub/" element={<Navigate to="/" />} />
        <Route path="/hub/:id" element={<HubPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
