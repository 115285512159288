import moment from "moment";
import apiClient from "../clients/api.client";
import { HubResource } from "../models/hub";
import { HubDates, HubTimes } from "../models/hubDates";

export const GetNearbyHubs = async (): Promise<HubResource[]> => {
  return await (
    await apiClient.get(`/public/hub/nearby`)
  ).data;
};

export const GetHubs = async (): Promise<HubResource[]> => {
  return await (
    await apiClient.get(`/public/hub/`)
  ).data;
};

export const GetHub = async (id: string): Promise<HubResource> => {
  return await (
    await apiClient.get(`/public/hub/${id}`)
  ).data;
};

export const GetHubDates = async (id: string): Promise<HubDates> => {
  return await (
    await apiClient.get(`/public/hub/${id}/dates`)
  ).data;
};

export const GetHubTimes = async (
  id: number,
  date: Date,
  orderId?: number
): Promise<HubTimes> => {
  const hubTimes = (
    await apiClient.get<HubTimes>(
      `/public/hub/${id}/${moment(date).format(
        "LL"
      )}/times?ignoreOrderId=${orderId}`
    )
  ).data;

  hubTimes.allocations = hubTimes.allocations.map((allocation) => {
    allocation.startDate = moment(allocation.startDate).utc(false).toDate();
    allocation.endDate = moment(allocation.endDate).utc(false).toDate();
    return allocation;
  });

  return hubTimes;
};
