import Page from "../../components/page";
import HubList from "../../components/hub.list.component";

const HomePage = () => {
  return (
    <Page title="WorkfromHub Host">
      <HubList />
    </Page>
  );
};

export default HomePage;
