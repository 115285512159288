import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BookingList from "../../components/booking.list.component";
import Page from "../../components/page";
import { HubResource } from "../../models/hub";
import { GetHub } from "../../services/hub.service";

const HubPage = () => {
  const { id } = useParams();

  const [hub, setHub] = useState<HubResource | undefined>();

  useEffect(() => {
    const fetchHub = async () => {
      if (id) {
        await GetHub(id)
          .then(async (x) => {
            setHub(x);
            console.log("got hub", x);
          })
          .catch((err) => {
            console.log(err);
            // TODO: 404 display
          });
      }
    };

    fetchHub();
  }, [id]);

  return (
    <>
      <Page title={hub?.name}>{hub?.id && <BookingList id={hub.id} />}</Page>
    </>
  );
};

export default HubPage;
